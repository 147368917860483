// Карточки
const flycomScrollLists = document.querySelectorAll('.flycom-list-scroll');
flycomScrollLists.forEach((flycomScrollList) => {
  const flycomScrollListOverlay = flycomScrollList.querySelector(
    '.flycom-list-overlay',
  );
  const leftSideEl = flycomScrollList.querySelector(
    '.flycom-list-gradient:first-child',
  );
  const rightSideEl = flycomScrollList.querySelector(
    '.flycom-list-gradient:not(:first-child)',
  );
  const checkScoll = (scrollLeft) => {
    const totalWidth = flycomScrollListOverlay.scrollWidth;
    const screenWidth = flycomScrollList.clientWidth;

    if (totalWidth === screenWidth) {
      leftSideEl.style.opacity = 0;
      rightSideEl.style.opacity = 0;
    } else {
      const leftPos = scrollLeft;
      const leftOpacity = leftPos / (totalWidth - screenWidth);
      const rightOpacity = 1 - leftOpacity;
      leftSideEl.style.opacity = leftOpacity;
      rightSideEl.style.opacity = rightOpacity;
    }
  };
  checkScoll(0);
  flycomScrollListOverlay.addEventListener('scroll', (e) =>
    checkScoll(e.target.scrollLeft),
  );
  const leftSideArrow = leftSideEl.querySelector('div');
  if (leftSideArrow) {
    leftSideArrow.addEventListener('click', (e) => {
      flycomScrollListOverlay.scrollTo({
        left: flycomScrollListOverlay.scrollLeft - 255,
        behavior: 'smooth',
      });
    });
  }
  const rightSideArrow = rightSideEl.querySelector('div');
  if (rightSideArrow) {
    rightSideArrow.addEventListener('click', (e) => {
      flycomScrollListOverlay.scrollTo({
        left: flycomScrollListOverlay.scrollLeft + 255,
        behavior: 'smooth',
      });
    });
  }
});

// Выбор типа услуги (дом или бизнес)
document.querySelectorAll('.flycom-service-type-picker').forEach((picker) => {
  const select = picker.querySelector('select');
  select.style.display = 'none';
  picker.style.display = 'inline-block';

  const titleEl = document.createElement('span');
  titleEl.className = 'flycom-service-type-picker-title';
  titleEl.textContent = select.options[select.selectedIndex].text;
  picker.prepend(titleEl);

  const dropdownEl = document.createElement('div');
  dropdownEl.style.position = 'absolute';
  dropdownEl.style.display = 'none';
  dropdownEl.className = 'flycom-service-type-picker-dropdown';

  [...select.options].forEach((option) => {
    const optionEl = document.createElement('div');
    optionEl.textContent = option.textContent;
    optionEl.dataset.value = option.value;
    optionEl.className = 'flycom-service-type-picker-dropdown-option';
    dropdownEl.appendChild(optionEl);
  });

  picker.appendChild(dropdownEl);

  const onClickOutside = (e) => {
    if (!e.target.className.includes('flycom-service-type-picker')) {
      dropdownEl.style.display = 'none';
      window.removeEventListener('click', onClickOutside);
    }
  };

  picker.addEventListener('click', (e) => {
    if (e.target.className === 'flycom-service-type-picker-dropdown') return;
    if (e.target.className === 'flycom-service-type-picker-dropdown-option') {
      select.value = e.target.dataset.value;
      titleEl.textContent = e.target.textContent;
    }
    if (dropdownEl.style.display === 'none') {
      dropdownEl.style.display = 'block';
      window.addEventListener('click', onClickOutside);
    } else {
      dropdownEl.style.display = 'none';
      window.removeEventListener('click', onClickOutside);
    }
  });
});

// Выбор локации
const limitTownsByDistrict = (disctict) => {
  document.querySelectorAll('.flycom-service-location-town').forEach((loc) => {
    const select = loc.querySelector('select');
    select.value = 'placeholder';
    loc.querySelector('.flycom-service-location-select-title').textContent =
      select.options[select.selectedIndex].text;
    document
      .querySelectorAll('.flycom-service-plan-title')
      .forEach((planTitle) => {
        planTitle.classList.add('disabled');
      });
    document.querySelectorAll('.flycom-service-plan-deck').forEach((deck) => {
      deck.classList.add('disabled');
    });
    document
      .querySelectorAll('.flycom-service-plan-deck button')
      .forEach((cardButton) => {
        cardButton.disabled = true;
      });
  });
  document
    .querySelectorAll(
      '.flycom-service-location-town .flycom-service-location-dropdown div',
    )
    .forEach((option) => {
      if (option.dataset.parent === disctict) option.style.display = 'block';
      else option.style.display = 'none';
    });
};

document.querySelectorAll('.flycom-location-select').forEach((select) => {
  const container = select.parentElement;
  select.style.display = 'none';

  const id = select.name;

  const titleEl = document.createElement('div');
  titleEl.className = 'flycom-service-location-select-title';
  titleEl.textContent = select.options[select.selectedIndex].text;
  container.appendChild(titleEl);

  const dropdownEl = document.createElement('div');
  dropdownEl.id = id;
  dropdownEl.className = 'flycom-service-location-dropdown';
  dropdownEl.style.display = 'none';

  [...select.options].forEach((option) => {
    if (option.value === 'placeholder') return;
    const optionEl = document.createElement('div');
    optionEl.textContent = option.textContent;
    optionEl.dataset.value = option.value;
    optionEl.dataset.parent = option.dataset.parent;
    optionEl.className = 'flycom-service-location-dropdown-option';
    dropdownEl.appendChild(optionEl);
    optionEl.addEventListener('click', (e) => {
      select.value = optionEl.dataset.value;
      titleEl.textContent = select.options[select.selectedIndex].text;
      if (select.classList.contains('flycom-district-select')) {
        document.querySelector('.flycom-town-select').disabled = false;
        document
          .querySelector('.flycom-service-location-town')
          .classList.remove('disabled');

        limitTownsByDistrict(select.value);
      }
      dropdownEl.style.display = 'none';
      window.removeEventListener('click', onClickOutside);
      if (e.target.parentElement.id === 'service-location') {
        document
          .querySelectorAll('.flycom-service-plan-title')
          .forEach((planTitle) => {
            planTitle.classList.remove('disabled');
          });
        document
          .querySelectorAll('.flycom-service-plan-deck')
          .forEach((deck) => {
            deck.classList.remove('disabled');
          });
        document
          .querySelectorAll('.flycom-service-plan-deck button')
          .forEach((cardButton) => {
            cardButton.disabled = false;
          });
      }
    });
  });
  container.appendChild(dropdownEl);

  const onClickOutside = (e) => {
    if (dropdownEl !== e.target && !container.contains(e.target)) {
      dropdownEl.style.display = 'none';
      window.removeEventListener('click', onClickOutside);
    }
  };
  titleEl.addEventListener('click', (e) => {
    if (select.disabled) {
      dropdownEl.style.display = 'none';
    } else {
      if (dropdownEl.style.display === 'none') {
        dropdownEl.style.display = 'block';
        window.addEventListener('click', onClickOutside);
      } else {
        dropdownEl.style.display = 'none';
        window.removeEventListener('click', onClickOutside);
      }
    }
  });
});

// При открытии модала заполнить невидимые поля

document
  .querySelectorAll('.flycom-service-plan-card-button')
  .forEach((button) => {
    button.addEventListener('click', (e) => {
      const plan = e.target.dataset.plan;
      document.querySelector('.flycom-modal-district').textContent =
        document.querySelector('.flycom-district-select').value + ', ';
      document.querySelector('.flycom-modal-town').textContent =
        document.querySelector('.flycom-town-select').value;
      document.querySelector('#flycomDistrictInput').value =
        document.querySelector('.flycom-district-select').value;
      document.querySelector('#flycomTownInput').value = document.querySelector(
        '.flycom-town-select',
      ).value;
      document.querySelector('#flycomPlanInput').value = plan || '';
    });
  });
